<template>
	<div class="maxMaxth evebt evebtCone">
<!-- userInfo -->
				
		<div class="cRKA8AT8lf2bItsMSztA">
			<div class="a7neOLNNGzlPwu32tGO4">
				<div class="v0FZl0YCkbZ5KOXk68M4 van-dropdown-menu-box">
					<van-dropdown-menu @change="qingqList">
						<van-dropdown-item @change="qingqList" v-model="timValue" :options="timOption" />
					</van-dropdown-menu>
				</div>
				<div class="RnNfgaFEYzhMdHzQIun2">
					<span>{{ $t('withdraw._TotaldeSaques') }}</span>
					<span class="XGEOwGXgDL9a2SebPqvj">{{tabList.total}}</span>
					<span class="tenSlis_ss" @click="animate">
						<svg-icon ref="thsAlis" className="tenSlis" icon-class="comm_icon_retry--svgSprite:all" />
					</span>
				</div>
			</div>
		</div>
		<!-- withdraw-details -->
		<div class="TBtGFT5INIot5sEUIm8w vantDataList NxnCNaElf8M888qRq8IV">
			<!-- <div role="feed" class="van-list"> -->
			<van-list v-if="tabList && tabList.list.length" v-model="loading" @load="onLoad" :finished="finished" >
				<div class="RI492L24wIobigOikbe7" v-for="(item,index) in tabList.list" :key="index"
					@click="$router.push({name:'withdraw-details',query:{
							content:JSON.stringify(item)
						}})"
				>
					<div class="YKzqf0JGmCBnfizRLYUO">
						<div class="TpPGgtgOALV55KtKdz2x"><img
								src="./../../assets/icons/images/icon_normal_pix.png" alt="."
								class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton" basescale="0.8"
								style="width: 0.6rem; height: 0.6rem;">
						</div>
						<div class="T999CGNh9PUP53GejRXV">
								<p class="m7mOlKrXO3IjS5HZydS6">{{ $t('withdraw._EmitindoparaPIX') }}({{item.account_no}})</p>
								<p class="nihxzf02sOHDtKWB_3CK">{{item.time}}</p>
						</div>
					</div>
					<div class="ExMv5n49yF9OtHqzZxjp">
						<p class="KDtBp6sOUX6NEhwwQsdF">{{item.money}}</p>
						<!-- .KYYvz9FP2NopeXMcakPR {
						    color: var(--theme-secondary-color-finance)
						}
						
						.ndJ_zm5K047iicfJJUE1 {
						    color: var(--theme-secondary-color-success)
						}
						
						.xxTQd0bNMWIQEWt9TUaI {
						    color: var(--theme-secondary-color-error)
						} -->
						<p class="nihxnrnUd3rdJFAdzEiZ KYYvz9FP2NopeXMcakPR" v-if="item.status<=1" >Esperando Retirada </p>
						<p class="nihxnrnUd3rdJFAdzEiZ ndJ_zm5K047iicfJJUE1" v-if="item.status==2" >Retirada Bem Sucedida</p>
						<p class="nihxnrnUd3rdJFAdzEiZ xxTQd0bNMWIQEWt9TUaI" v-if="item.status==3" >Retirada Cancelada</p>
							<!-- 状态:<=1:提款中, 2:已提款,3:已拒绝 -->
						<!--  -->
						<!--  -->
					</div>
				</div>
				<!-- <div class="van-list__placeholder"></div> -->
			<!-- </div> -->
			</van-list>
			<van-empty v-if="!tabList.list.length" image-size="2.1rem" class="custom-image"
				:image="img_none_sj"
				:description="$t('description')" />
			
		</div>
		<!-- list -->

		
	</div>
</template>
<script>
	import {
		tim,
		timValue
	} from "@/utils/tim.js"
	import {
		withdrawRecords,
	} from "@/apis/modules/login.js"
	export default {
		data() {
			return {
				isTanzhans: false,
				loading: false,
				finished: false,
				timValue: timValue,
				timOption: tim,
				tabList: {},
				fromType:1,
				formData: {
					time: '',
				},
				//Todos os Tipos     Troca de Fundos     Recarga de Membro     Saque de Membro     Liquidação do Comerciante     Correção de Fundo     Eventos     Rebate     Reembolso     Juros     Missão     Recompensa VIP     Oferta de Depósito     Clube     Auditoria em Segundo Plano     Fundo de
				// Previdência     Detalhes da Subcategoria     
			};
		},
		mounted() {
			let {current}=this.$route.query;
			if(current==3){
				this.withdrawRecords();
			}
		},
		methods: {
			onLoad(e) {
				console.log(e)
				if(this.finished){
					return false;
				}
				
				this.fromType=this.fromType+1;
				this.withdrawRecords()
			},
			qingqList(e) {
				this.tabList={};
				this.fromType=1;
				this.finished=false;
				this.timValue = e;
				this.withdrawRecords()
			},
			animate(e) {
				let target = e.target;
				let tagName = target.tagName.toLowerCase()
				if (tagName == 'svg') {
					target.classList.add('animate__manimated');
					let time = setTimeout(() => {
						clearTimeout(time)
						target.classList.remove('animate__manimated')
					}, 1000)
					this.withdrawRecords()
				}
			},
			withdrawRecords() {
				// thsAlis
				withdrawRecords({
					...this.formData,
					time: this.timValue,
					page:this.fromType,
				}).then(({
					code,
					msg,
					data
				}) => {
					if (code == 0) {
						if(JSON.stringify(data.list)=='[]'){
							this.finished = true;
						}
						if(data.list && this.tabList.list){
							data.list = [...this.tabList.list,...data.list]
						}
						if(data.list.length==data.count){
							this.finished = true;
						}
						this.loading = false;
						if(this.fromType==1){
							this.tabList = data;
						}else{
							this.tabList.list = data.list
						}
						// this.tabList = data;
					}
				})
			},
		}
	};
</script>
<style>
	.evebtCone .van-dropdown-menu-box .van-popup{
		overflow: hidden;
	}
</style>
<style scoped>
	.v0FZl0YCkbZ5KOXk68M4 {
		display: flex;

	}

	.cRKA8AT8lf2bItsMSztA {
		height: .9rem;
		padding: 0 .2rem;
	}

	.a7neOLNNGzlPwu32tGO4 {
		-ms-flex-align: center;
		-ms-flex-pack: end;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		height: .5rem;
		height: .9rem;
		justify-content: space-between;
	}

	.cRKA8AT8lf2bItsMSztA {
		display: -ms-flexbox;
		font-size: .24rem;
		line-height: 1.5;
		color: var(--cms-text-color-secondary);
	}

	.XGEOwGXgDL9a2SebPqvj {
		/* color: var(--theme-secondary-color-finance) */
		color: var(--cms-text-color-primary)
	}

	.tenSlis_ss {
		margin-left: .2rem;
	}

	.tenSlis_ss .tenSlis {
		color: var(--cms-primary-color);
		font-size: .3rem;
		width: .3rem;
		height: .3rem;

	}

	.TBtGFT5INIot5sEUIm8w {
		-ms-flex-pack: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		height: calc(100vh - 2.55rem);
		height: calc(var(--vh, 1vh)*100 - 2.55rem);
		justify-content: center;
		overflow: hidden;
		position: relative
	}

	.TBtGFT5INIot5sEUIm8w {
		-ms-flex-pack: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		overflow: scroll;
		height: calc(100vh - 2.55rem);
		height: calc(var(--vh, 1vh)*100 - 2.55rem);
		justify-content: center;
		overflow: hidden;
		box-sizing: border-box;
		position: relative
	}

	.TBtGFT5INIot5sEUIm8w .van-list {
		-ms-flex-positive: 1;
		flex-grow: 1;
		margin-right: .07rem;
		box-sizing: border-box;
		overflow: scroll;
		padding: 0 .13rem 0 .2rem
	}

	

	.TBtGFT5INIot5sEUIm8w .van-list.empty {
		-ms-flex-positive: initial;
		flex-grow: 0
	}

	.TBtGFT5INIot5sEUIm8w .van-list>div {
		border-radius: .1rem;
		padding: .2rem
	}

	.TBtGFT5INIot5sEUIm8w .van-list>div:nth-child(odd) {
		background-color: var(--cms-background-color-primary)
	}

	.TBtGFT5INIot5sEUIm8w .van-list>div:nth-child(2n) {
		/* background-color: var(--theme-bg-color) */
	}

	.TBtGFT5INIot5sEUIm8w .van-list>div.van-list__loading {
		background-color: transparent !important;
		color: var(--theme-text-color-lighten)
	}

	.TBtGFT5INIot5sEUIm8w .van-list>div.van-list__placeholder {
		background-color: transparent !important
	}

	.TBtGFT5INIot5sEUIm8w .van-list.reverse>div:nth-child(odd) {
		/* background-color: var(--theme-bg-color) */
	}

	.TBtGFT5INIot5sEUIm8w .van-list.reverse>div:nth-child(2n) {
		background-color: var(--theme-main-bg-color)
	}

	.TBtGFT5INIot5sEUIm8w .van-empty {
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding: 0
	}

	.TBtGFT5INIot5sEUIm8w .van-empty .van-empty__image {
		height: 2.1rem;
		text-align: center;
		width: 2.1rem
	}

	.TBtGFT5INIot5sEUIm8w .van-empty .van-empty__description {
		color: var(--theme-text-color-lighten);
		font-size: .26rem;
		margin-top: .2rem
	}

	.TBtGFT5INIot5sEUIm8w .van-list__loading {
		padding: 0 !important
	}

	.TBtGFT5INIot5sEUIm8w .van-list__loading .loading__box {
		font-size: .24rem;
		margin-right: .1rem !important
	}

	.TBtGFT5INIot5sEUIm8w .van-list__loading .loading__box>div {
		zoom: 1;
		-webkit-transform: scale(1);
		transform: scale(1)
	}

	.TBtGFT5INIot5sEUIm8w .van-list__error-text {
		background-color: transparent !important
	}

	.TBtGFT5INIot5sEUIm8w .FgEUt_kRHRTXSTPCORUT {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		font-size: .24rem;
		justify-content: center
	}

	.TBtGFT5INIot5sEUIm8w .wjue60N7etC82d435zNx {
		background-color: var(--theme-main-bg-color);
		border-top: thin solid var(--theme-color-line);
		z-index: 20
	}
	.RI492L24wIobigOikbe7 {
	    -ms-flex-pack: justify;
	    justify-content: space-between;
		display: flex;
	    padding: .2rem
	}
	
	.RI492L24wIobigOikbe7,.RI492L24wIobigOikbe7 .YKzqf0JGmCBnfizRLYUO {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex
	}
	
	.RI492L24wIobigOikbe7 .YKzqf0JGmCBnfizRLYUO .TpPGgtgOALV55KtKdz2x {
	    border-radius: .1rem;
	    height: .6rem;
	    margin-right: .2rem;
	    width: .6rem;
		display: flex;
	}
	
	.RI492L24wIobigOikbe7 .YKzqf0JGmCBnfizRLYUO .m7mOlKrXO3IjS5HZydS6 {
	    color: var(--cms-text-color-secondary);
	    font-size: .22rem
	}
	
	.RI492L24wIobigOikbe7 .YKzqf0JGmCBnfizRLYUO .T999CGNh9PUP53GejRXV .nihxzf02sOHDtKWB_3CK {
	    -ms-flex-align: center;
	    -ms-flex-pack: start;
	    align-items: center;
	    color: var(--cms-text-color-primary);
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .2rem;
	    justify-content: flex-start
	}
	
	.RI492L24wIobigOikbe7 .YKzqf0JGmCBnfizRLYUO .T999CGNh9PUP53GejRXV .nihxzf02sOHDtKWB_3CK .ug_IitdKY0uaKWAaiRiB {
	    color: var(--theme-primary-color);
	    display: -ms-flexbox;
	    display: flex;
	    margin-left: .08rem
	}
	
	.RI492L24wIobigOikbe7 .ExMv5n49yF9OtHqzZxjp {
	    -ms-flex: 1;
	    flex: 1;
	    text-align: right
	}
	
	.RI492L24wIobigOikbe7 .ExMv5n49yF9OtHqzZxjp .KDtBp6sOUX6NEhwwQsdF {
	    color: var(--cms-primary-color);
	    font-size: .24rem;
	    font-weight: 700
	}
	
	.RI492L24wIobigOikbe7 .ExMv5n49yF9OtHqzZxjp .nihxnrnUd3rdJFAdzEiZ {
	    font-size: .22rem
	}

	
	.KYYvz9FP2NopeXMcakPR {
	    color: var(--theme-secondary-color-finance)
	}
	
	.ndJ_zm5K047iicfJJUE1 {
	    color: var(--theme-secondary-color-success)
	}
	
	.xxTQd0bNMWIQEWt9TUaI {
	    color: var(--cms-text-color-secondary)
	}

</style>
