<template>
	<header class="ant-layout-header -wsiy pCKI81C6T6W2e4ZAfsHW uJNjizvqNTc61kuO2wZO"  :style="{
		height:`${$store.state.xiazApp?.9+.7:.9}rem`,
		transition: '.3s',
		
	}" >
	
		<!-- :offset-top=""  -->
		<div class="ant-layout-header-conter"  style="background-color: var(--cms-top-background-color);">
			<div class="v7Gu3VSoEuypS8B9EgaJ" v-if="$store.state.xiazApp" style="display:flex">
				<div class="aG5W8rsptmZ9RO66S3cI" style="background-color: var(--b-app-rgba);">
					<div class="pxU3k4yaAHPfT3wK4R8v" @click.stop="qingCHuApp" style="display:flex">
						<svg-icon
							class="svg"
							style=" color: rgb(var(--theme-ant-primary-color-19));width:.2rem;height:.2rem"
							icon-class="comm_icon_x--svgSprite:all" />
					</div>
					<div style="display:flex" class="DAEXSUBjuzUY1EoIAeEn LubvNYW6hJ8aFPwvFVVB"><img
							:src="config.home_download_image" alt="."
							class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton" style="height: 0.51rem;z-index: 1;"></div>
					<div  @click.stop="$store.commit('publicFun',['AppDownload',true])" style="display:flex" class="DvuNDsU5DEFvvcIAXWAe"><button  type="button" class="ant-btn ant-btn-default"
							style="background-color: rgb(var(--theme-ant-primary-color-19));     color: var(--theme-primary-font-color); border: none;"><span>Baixar
								Agora</span></button></div>
				</div>
			</div>
			<van-nav-bar :style="{
				'background-image':`url(${config.navigate_bg_image})`,
				'background-size': 'initial',
				
			}" class="_Hp2NEpB_AxXucPPcXBD yejv0HlPp2rPD2V4Tg4O">
				
				<div class="XmxJUtbUHHdaQg3o2iDA " slot="left">
					<div class="ant-layout bkh5uQUT1mtuXLoAMzMG" @click.stop="show_Popup()">
						<svg-icon :className="`${$store.state.showPopup?'fs20':'fs201'}`"
							icon-class="btn_zcl_arrow--svgSprite:all" />
					</div>
					<div class="k2aKMnWti6qDRzhUpO8W">
						<img :src="config.home_logo" v-lazy="config.home_logo" loading="loaded"
							class="goDRiiBsuEuXD3W1NphN II_5ZM4k4hTlrmJknbDi customLogo">
					</div>
				</div>
				<div class="geSGAKCHnajLSE515jEg" slot="right">
					<div class="crcd82r1PVsQivOcCftk" v-if="!vuex_token_head">
						<van-button @click="$store.commit('isRecord'),$store.commit('publicFun',['isRecordType',1])"
							type="primary" size="mini">{{$t('Login._Login')}}</van-button>
						<van-button @click="$store.commit('isRecord'),$store.commit('publicFun',['isRecordType',0])"
							type="default" size="mini"> {{$t('Login._Registro')}} </van-button>
					</div>
					<div class="GgxC_2tF_TQt1DVfif5a" v-else>
						<section class="iODDzDZbB06hkkF6ltjq">
							<div class="vy918omw_N7rBkJ235XX"
								style="--row-align: center; --mode: flex; --column-align: center;">
								<div class="lop_top" :style="{
										backgroundImage:`url(${img_hb_frame})`
									}" style="background-position: 0px 0px;background-size: 100% 100%;">
									<i class="yj5rHjAv0U7RlHMzWr7F img skeleton-box" :style="{
											backgroundImage:`url(${_coloImg})`
										}" style="display: inline-block; position: relative; width: 0.3rem; height: 0.3rem;background-position: -7.15714rem -2.79643rem; background-size: 7.77857rem 7.59107rem;"></i>
								</div>
								<section class="_top_currency-count" style="font-size: 0.18rem;">
									<div class="countTextBox" @click="animate">
										<span class="SSAbrhtT3U690CrzLUd5 count-to">
											<span class="notranslate">{{moneyInfo.balance_total_str}}</span>
										</span>
										<svg-icon icon-class="comm_icon_sx--svgSprite:all" />
									</div>
								</section>
							</div>
						</section>
						<section class="aLXMjsO8hJ5Mj1gtyJTg top-selectDownArrow">
							<van-button @click="$store.commit('isDeposit',true)" class="van-but_ton" type="primary">
								<span>{{$t('Login._Depósito')}} </span>
							</van-button>
							<!-- $store.state.isDeposit -->
							<!-- ant-dropdown-open -->
							<i @click="modelCon=!modelCon" :class="{
								'ant-dropdown-open':modelCon
							}" class="anticon ant-dropdown-trigger ">
								<svg-icon style="width: .15rem;height: .15rem;"
									icon-class="comm_icon_sort--svgSprite:all" />
							</i>
							<div class="ant-dropdown ant-dropdown-placement-bottomRight" :style="{
									display:`${modelCon?'':'none'}`,
								}" style="left: 34px; top: .65rem;width:70%;position: absolute;">
								<ul role="menu" tabindex="0"
									class="ant-dropdown-menu ant-dropdown-menu-vertical ant-dropdown-menu-root ant-dropdown-menu-light ant-dropdown-content">
									<li @click="routerFun('withdraw');" role="menuitem"
										class="ant-dropdown-menu-item"> {{$t('Login._Saque')}}</li>
									<li @click="$router.push({name:'yuebao'})" role="menuitem"
										class="ant-dropdown-menu-item"> {{$t('Login._Juros')}} </li>
									<!-- withdraw -->
									<!-- yuebao -->
								</ul>
							</div>
						</section>
					</div>
					<!-- <div class="FAoJf6QlYLI4Sv32WCTh" @click="$router.push({name:'search'})">
						<svg-icon className="fs20" icon-class="comm_icon_ss--svgSprite:all" />
					</div> -->
				</div>
			</van-nav-bar>
			
		</div>

	</header>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'Icon',
		props: {

		},
		data() {
			return {
				img_hb_frame: require('./../../assets/icons/images/img_hb_frame.webp'),
				show: false,
				modelCon: false,
			}
		},
		computed: {
			...mapState(['userInfo', 'config', 'vuex_token', 'vuex_token_head', 'moneyInfo'])
		},
		created() {
			// this.qingCHuApp();
		},
		methods: {
			// <!--qingCHuApp -->
			AppDownload(){
				
			},
			routerFun(name){
				if(this.$store.state.userInfo && name=='withdraw' ){
					if(!this.$store.state.userInfo.set_withdraw_password){
						this.$router.push({name:'security'})
						return false;
					}
				}
				this.$router.push({name:'withdraw'})
			},
			qingCHuApp(){
				this.$vuex('xiazApp',false)
				this.$store.commit('publicFun',['xiazApp',false]) 
			},
			show_Popup() {
				this.$store.commit('showPopup')
				// this.$store.commit('publicFun',['showPopup',true]) 
			}
		}
	}
</script>
<style>
	.van-nav-bar__left:active,
	.van-nav-bar__right:active {
		opacity: 1 !important;
	}

	.top-selectDownArrow .van-but_ton:active {
		opacity: 1 !important;
	}

	.-wsiy .van-nav-bar__left
	{
		padding: 0 .1rem 0 .1rem;
		position: relative !important;
	}
	.-wsiy .van-nav-bar__right {
		padding: 0 .2rem 0 0rem;
		position: relative !important;
	}
</style>
<style scoped>
	.lop_top {
		width: .34rem;
		height: .34rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ODpmAuZ5P5dWvxbKwcOf {
	    height: .7rem
	}
	.v7Gu3VSoEuypS8B9EgaJ {
	    -ms-flex-negative: 0;
	    flex-shrink: 0;
	    height: 100%;
	    width: 100%
	}
	.aG5W8rsptmZ9RO66S3cI {
		height: .7rem;
		width: 100%;
		display: flex;
		padding-right: .2rem;
		box-sizing: border-box;
		align-items: center;
		justify-content: space-between;
	}
	
	.aG5W8rsptmZ9RO66S3cI,.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	}
	
	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v {
	    -ms-flex-pack: center;
	    cursor: pointer;
	    justify-content: center
	}
	
	.aG5W8rsptmZ9RO66S3cI .MzKJCo_kccEXopglGrWF {
	    background-color: var(--theme-table-bg-color);
	    cursor: pointer
	}
	
	.aG5W8rsptmZ9RO66S3cI .MzKJCo_kccEXopglGrWF img {
	    will-change: auto!important
	}
	
	.aG5W8rsptmZ9RO66S3cI .DvuNDsU5DEFvvcIAXWAe {
	    -ms-flex-negative: 0;
	    flex-shrink: 0;
	    margin-left: auto;
	}
	
	.aG5W8rsptmZ9RO66S3cI .DvuNDsU5DEFvvcIAXWAe .ant-btn {
	    border-radius: .1rem;
	    font-family: MicrosoftYaHeiLobby;
	    text-align: center
	}
	
 .aG5W8rsptmZ9RO66S3cI {
	    /* gap: .1rem */
	}
	
.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v {
	    padding: 0 .2rem;
	    position: relative
	}
	
.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v:after {
	    border-right: .01rem solid var(--theme-color-line);
	    content: "";
	    height: .4rem;
	    position: absolute;
	    right: 0;
	    top: 50%;
	    -webkit-transform: translateY(-50%);
	    transform: translateY(-50%)
	}
	
	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v svg {
	    font-size: .16rem
	}
	
	 .aG5W8rsptmZ9RO66S3cI .DvuNDsU5DEFvvcIAXWAe .ant-btn {
	    font-size: .16rem;
	    height: .4rem;
	    padding: 0 .18rem
	}
	
	.aG5W8rsptmZ9RO66S3cI {
	    /* gap: .2rem */
	}
	
	 .aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v {
	    margin-right: -.2rem;
	    padding: 0 .3rem
	}
	
	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v svg {
	    font-size: .2rem
	}
	
	.aG5W8rsptmZ9RO66S3cI .DvuNDsU5DEFvvcIAXWAe .ant-btn {
	    font-size: .18rem;
	    height: -webkit-fit-content;
	    height: -moz-fit-content;
	    height: fit-content;
	    line-height: .2rem;
	    max-width: 1.6rem;
	    min-height: .45rem;
	    min-width: 1.2rem;
	    padding-left: .1rem;
	    padding-right: .1rem;
	    white-space: normal;
	    word-break: break-all
	}

	._top_currency-count .notranslate {
		color: var(--cms-top-money-color);
		font-size: .26rem;
		 white-space: nowrap; /* 防止文本换行 */
		overflow: hidden; /* 隐藏超出容器的文本 */
		text-overflow: ellipsis; /* 在文本末尾添加省略号 */
		max-width: 1.2rem;
		display: inline-block;
		border-bottom: .02rem solid var(--cms-top-money-color);
	}

	._top_currency-count .countTextBox {
		display: flex;
		align-items: center;
	}

	._top_currency-count .svg-icon {
		color: var(--cms-top-btn-color);
		font-size: .293rem;
		width: .293rem;
		height: .293rem;
		z-index: 10
	}

	._top_currency-count .SSAbrhtT3U690CrzLUd5 {
		display: block;
		margin: 0 .073rem 0 .1rem;
		max-width: 1.64rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.GgxC_2tF_TQt1DVfif5a {
		display: flex;
		align-items: center;
	}
	.ant-layout-header-conter{
		position:fixed;
		transition: .3s;
		width: 100%;
		z-index:99
	}
	.vy918omw_N7rBkJ235XX {
		height: .4rem;
		padding: 0 .084rem 0 .02rem;
		border: thin solid var(--cms-divider-color);
		border-radius: .2rem;
		-ms-flex-align: var(--column-align);
		-ms-flex-pack: var(--row-align);
		align-items: var(--column-align);
		display: var(--mode);
		height: .4rem;
		justify-content: var(--row-align);
		line-height: normal;
		padding: 0 .05rem 0 .02rem;
		position: relative;
	}

	.aLXMjsO8hJ5Mj1gtyJTg .ant-dropdown {
		background-color: var(--cms-background-color-secondary);
		border: .01rem solid var(--cms-divider-color);
		border-radius: .1rem;
		transition: .3s;
	}

	.aLXMjsO8hJ5Mj1gtyJTg .ant-dropdown-menu-item {
		color: var(--cms-text-color-primary);
		font-size: .24rem;
		height: .8rem;
		padding: 0 .19rem;
		line-height: .8rem;
		margin: 0;
		min-width: 1.7rem;
		text-align: left
	}

	.top-selectDownArrow .van-but_ton {
		border-radius: .1rem 0 0 .1rem;
		font-size: .22rem;
		height: .52rem;
		margin-left: .1rem;
		padding: 0 .38rem;
	}

	.top-selectDownArrow .ant-dropdown-trigger {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: var(--cms-top-btn-color);
		border-color: var(--cms-top-btn-color);
		border-radius: 0 .1rem .1rem 0 !important;
		color: var(--cms-top-text-color);
		display: -ms-flexbox;
		display: flex;
		height: .52rem;
		transition: .3;
		justify-content: center;
		margin-left: -.01rem;
		position: relative;
		width: .4rem;
	}

	.ant-dropdown-trigger.ant-dropdown-open>svg {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg)
	}

	.top-selectDownArrow .ant-dropdown-trigger:after {
		background: var(--cms-top-text-color);
		content: "";
		display: block;
		height: .24rem;
		left: 0;
		opacity: .3;
		position: absolute;
		top: .15rem;
		width: .01rem
	}

	.top-selectDownArrow {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.pCKI81C6T6W2e4ZAfsHW {
		/* background-color: var(--theme-top-nav-bg); */
		/* border-bottom: indianred !important; */
		height: var(--theme-header-height);
		line-height: normal;
		padding: 0;
	}

	.van-nav-bar {
		background-color: var(--cms-top-background-color);
	}

	.bkh5uQUT1mtuXLoAMzMG {
		color: var(--cms-global-spare7-color);
		font-size: .263rem;
		margin: 0 .25rem 0 .08rem;
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
		transition: .3s;
	}

	.yejv0HlPp2rPD2V4Tg4O .fs20 {
		color: var(--cms-global-spare7-color) !important;
		transition: .3s;
	}

	.yejv0HlPp2rPD2V4Tg4O .fs201 {
		color: var(--cms-global-spare7-color) !important;
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg);
		transition: .3s;
	}

	.bkh5uQUT1mtuXLoAMzMG {
		font-size: .36rem;
		margin: 0 .2rem 0 0;
	}

	._Hp2NEpB_AxXucPPcXBD {
		/* position: fixed; */
		top: 0;
		width: 100%;
		z-index: 9999;

	}

	.XmxJUtbUHHdaQg3o2iDA {
		display: flex;
		justify-content: center;
		align-content: center;
	}

	.yejv0HlPp2rPD2V4Tg4O .k2aKMnWti6qDRzhUpO8W {
		display: -ms-flexbox;
		display: flex;
		height: .74rem;
		margin-left: 0;
		max-width: 3.3rem;
		min-width: 1.8rem;
	}

	.ant-layout {
		display: flex;
		align-items: center;
	}

	.II_5ZM4k4hTlrmJknbDi {
		cursor: pointer;
		-o-object-fit: contain;
		object-fit: contain;
	}

	.customLogo {
		height: 100%;
		width: auto;
		max-width: 100%;
		object-fit: contain;
	}

	.crcd82r1PVsQivOcCftk button:first-child {
		/* margin-right: .2rem; */
	}

	.crcd82r1PVsQivOcCftk button {
		height: .51rem;
		font-size: .22rem;
		min-width: 1rem;
		padding: 0 .31rem;
		border-radius: .1rem;
	}

	.yejv0HlPp2rPD2V4Tg4O .geSGAKCHnajLSE515jEg {
		-ms-flex-negative: 0;
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		flex-shrink: 0;
		position: static !important;
	}

	.yejv0HlPp2rPD2V4Tg4O .FAoJf6QlYLI4Sv32WCTh {
		color: var(--theme-text-color);
		font-size: .25rem;
		margin-left: .15rem;
	}

	.yejv0HlPp2rPD2V4Tg4O .FAoJf6QlYLI4Sv32WCTh .fs20 {
		font-size: .25rem;
		width: .25rem;
		height: .25rem;
		color: var(--cms-global-spare7-color) !important;
	}
</style>
