<template>
   <van-tabbar class="zw6KEcLcR0TZl9aKmnoW" v-model="active"  z-index="1000">
       <!--  -->
       <van-tabbar-item  v-for="(item,index) in tabClass" :key="index" style="position: relative;z-index: 99;"  @click="routerFun(item)" :name="item.router" >
           <span>{{$t('toolbarConter')[item.className]}}</span>
           <i class="anticon" slot="icon">
               <i class="yj5rHjAv0U7RlHMzWr7F img skeleton-box" :style="{
                   ...tbaCla,
                   backgroundPosition:`${active==item.router?item.active.backgroundPosition:item.tab.backgroundPosition}`,
                   backgroundImage:`url(${_coloImg})`,
               }" ></i>
           </i>
       </van-tabbar-item>
   		   <div class="pendant-AGM4D" v-if="config.ui_version==3.6" style="		  width: 100%;">
   		   	<img class="pendant-left-keCbM" v-if="config.festival_img.bottom_1 != '#'" :src="config.festival_img.bottom_1" alt="" />
   		   	<img class="pendant-center-bQBE8" v-if="config.festival_img.bottom_2 != '#'" :src="config.festival_img.bottom_2" alt="" />
   		   	<img class="pendant-right-Pu6sD" v-if="config.festival_img.bottom_3 != '#'" :src="config.festival_img.bottom_3" alt="" />
   		   </div>
   </van-tabbar>
  </template>
   
  <script>
  // 引入从iconfont 下载的symbox文件
  // import '@/assets/icons/iconfont-svg.js'
  // 引入本地的svg文件
  // 定义一个加载目录的函数
  const requireAll = requireContext => requireContext.keys().map(requireContext)
  const req = require.context('@/assets/icons/svg', false, /\.svg$/)
  // 加载目录下的所有的 svg 文件
  requireAll(req)
  import { mapState } from 'vuex';
  export default {
  	computed:{
  		...mapState(['popList','userInfo','moneyInfo','config']),
		iconName(){
		  return `${this.prefix}${this.name}`
		}
  	},
    name: 'Icon',
    props: {
      name: String,
      active: {
        type:[String],
        required:'home',
      },
    },
    data(){
        return {
			tabActive:'',
            tbaCla:{
                    "display":"inline-block",
                    "position":' relative',
                    "width": '0.5rem',
                    "height": '0.5rem',
                    "backgroundSize":'10.3714rem 10.1214rem',
            },
			toolbarConter:{
				_Inicio:"Inicio",
				_Promoção:"Promoção",
				_Agente:"Agente",
				_Suporte:"Suporte",
				_Perfil:"Perfil",
				_Depósito:'Depósito',
				_Saque:"Saque",
			},
            tabClass:[
                {
                    router:'home',
					className:'_Inicio',
                    tab:{
                        "backgroundPosition": '-4.94286rem -6.47143rem',
                    },
                    active:{
                        "backgroundPosition": '-5.45714rem -6.47143rem'
                    }
                },
                {
                    router:'event',
					className:'_Promoção',
                    tab:{
                        "backgroundPosition": '-7.28571rem -3.08571rem',
                    },
                    active:{
                        "backgroundPosition": '-7.28571rem -3.6rem'
                    }
                },
                {
					router:'promote',
					className:"_Agente",
                    tab:{
                        "backgroundPosition": '-5.97143rem -6.47143rem',
                    },
                    active:{
                        "backgroundPosition": '-7.28571rem -3.6rem'
                    }
                },
                {
					router:"notice",
					className:"_Suporte",
                    tab:{
                        "backgroundPosition": '-5.21429rem -5.95714rem',
                    },
                    active:{
                        "backgroundPosition": '-7.28571rem -3.6rem'
                    }
                },
                {	
					router:'mine',
					className:"_Perfil",
                    tab:{
                        "backgroundPosition": '-7.28571rem -1.02857rem',
                    },
                    active:{
                        "backgroundPosition": '-7.28571rem -1.52857rem'
                    }
                },
            ]
        };
    },
    methods:{
        routerFun(e){
			if(e.router=='tan'){
				this.$store.commit('isDeposit',true);
				this.active=this.tabActive;
				return false;
			}else{
				if(this.$store.state.userInfo && e.router=='withdraw' ){
					if(!this.$store.state.userInfo.set_withdraw_password){
						this.$router.push({name:'security'})
						return false;
					}
				}
				this.$router.push({name:e.router})
			}
			this.tabActive=e.router;
        }
    },
	created() {
		this.tabActive=this.active;
		if(typeof this._vuex_token()=='string'){
			console.log(this.tabClass)
			this.tabClass[2]={
					router:'tan',
					isname:'',
					className:"_Depósito",
                    tab:{
                        "backgroundPosition": '-3.15714rem -5.95714rem',
                    },
                    active:{
                        "backgroundPosition": '-7.28571rem -3.6rem'
                    }
                }
			this.tabClass[3]={
					router:'withdraw',
					className:"_Saque",
			        tab:{
			            "backgroundPosition": '-6.48571rem -6.47143rem',
			        },
			        active:{
			            "backgroundPosition": '-7.28571rem -3.6rem'
			        }
			    }
			// _Depósito:'Depósito',
			// _Saque:"Saque",
		}
	},
  }
  </script>
   
  <style  scoped>
	  .pendant-AGM4D,.pendant-left-keCbM {
	      height: 100%;
	      position: absolute;
	      left: 0;
	      top: 0
	  }
	  .pendant-center-bQBE8 {
	      height: 100%;
	      left: 50%;
	      position: absolute;
	      transform: translateX(-50%)
	  }
	  
	  .pendant-right-Pu6sD {
	      height: 100%;
	      position: absolute;
	      right: 0;
	      top: 0
	  }
    .zw6KEcLcR0TZl9aKmnoW{
        background: var(--cms-bottom-nav-bg-color);
        background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(84, 84, 84, 0)));
        background-image: linear-gradient(180deg, transparent, rgba(84, 84, 84, 0));
        -webkit-box-shadow: .02rem 0 .06rem 0 rgba(0, 0, 0, .1);
        box-shadow: .02rem 0 .06rem 0 rgba(0, 0, 0, .1);
        height: 1.24rem;
    }
    .anticon {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    display: inline-block;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
}
.zw6KEcLcR0TZl9aKmnoW .van-tabbar-item__text {
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: .54rem;
    line-height: 1.3;
    max-width: 1.6rem;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: pre-wrap;
}

.zw6KEcLcR0TZl9aKmnoW .van-tabbar-item {
	background: transparent;
    -ms-flex-pack: end;
    color: var(--cms-text-color-secondary);
    font-size: .24rem;
    justify-content: center;
    padding-top: 0;
    align-items: center;
    /* justify-content: flex-end; */
    position: relative;
}
.zw6KEcLcR0TZl9aKmnoW .van-tabbar-item--active {
    background: transparent;
    color: var(--cms-primary-color);
}
  </style>
  