<template>
	<div :style="{
		height:`${$store.state.xiazApp?.9+.7:.9}rem`,
		transition: '.3s',
		'background-color':'var(--cms-top-background-color)',
	}">
		<div class="v7Gu3VSoEuypS8B9EgaJ" v-if="$store.state.xiazApp" style="display:flex">
			<div class="aG5W8rsptmZ9RO66S3cI" style="background-color: var(--b-app-rgba);">
				<div class="pxU3k4yaAHPfT3wK4R8v" @click.stop="qingCHuApp" style="display:flex">
					<svg-icon class="svg"
						style=" color: rgb(var(--theme-ant-primary-color-19));width:.2rem;height:.2rem"
						icon-class="comm_icon_x--svgSprite:all" />
				</div>
				<div style="display:flex" class="DAEXSUBjuzUY1EoIAeEn LubvNYW6hJ8aFPwvFVVB"><img
						:src="config.home_download_image" alt="."
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="height: 0.51rem;z-index: 1;"></div>
				<div @click.stop="$store.commit('publicFun',['AppDownload',true])" style="display:flex"
					class="DvuNDsU5DEFvvcIAXWAe"><button type="button" class="ant-btn ant-btn-default"
						style="background-color: rgb(var(--theme-ant-primary-color-19));     color: var(--theme-primary-font-color); border: none;"><span>Baixar
							Agora</span></button></div>
			</div>
		</div>
		<div class="QP3BhRt03qv1Gwd20Gjx"
		 :style="{
			 'background-image':`url(${config.background_image})`
		 }"
		>
			<div @click.stop="show_Popup" style="display: flex;align-items: center;z-index: 9;position: relative;">
				<svg-icon class="menComes" icon-class="RbbrBFJf-icon_dt_1cd:all" />
			</div>
			<img :src="config.home_logo" v-lazy="config.home_logo" loading="loaded" class="krx_BqY0Ot5YxLi63BNa">
			<div class="pendant-I0d9y">
				<img class="pendant-left-vQVTe"
					v-if="config.festival_img.top_1 != '#'"
					:src="config.festival_img.top_1" alt="">
					<img class="pendant-center-vjDmr"
					v-if="config.festival_img.top_2 != '#'"
					:src="config.festival_img.top_2" alt="">
				<img class="pendant-right-PUsnH"
					v-if="config.festival_img.top_3 != '#'"
					:src="config.festival_img.top_3" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'Icon',
		props: {

		},
		data() {
			return {
				img_hb_frame: require('./../../assets/icons/images/img_hb_frame.webp'),
				show: false,
				modelCon: false,
			}
		},
		computed: {
			...mapState(['userInfo', 'config', 'vuex_token', 'vuex_token_head', 'moneyInfo'])
		},
		created() {},
		methods: {
			AppDownload() {

			},
			qingCHuApp() {
				this.$vuex('xiazApp', false)
				this.$store.commit('publicFun', ['xiazApp', false])
			},
			show_Popup() {
				this.$store.commit('showPopup')
				// this.$store.commit('publicFun',['showPopup',true]) 
			}
		}
	}
</script>

<style scoped>
	.pendant-I0d9y{
	    height: 100%;
		width: 100%;
	    position: absolute;
	    left: 0;
	    top: 0
	}
	.pendant-left-vQVTe {
	    height: 100%;
	    position: absolute;
	    left: 0;
	    top: 0
	}
	
	.pendant-center-vjDmr {
	    height: 100%;
	    left: 50%;
	    position: absolute;
	    transform: translateX(-50%)
	}
	
	.pendant-right-PUsnH {
	    height: 100%;
	    position: absolute;
	    right: 0;
	    top: 0
	}
	.QP3BhRt03qv1Gwd20Gjx {
		height: .9rem;
		position: relative;
		display: flex;
		align-items: center;
		z-index: 101;
		position: fixed;
		left: 0s;
		width: 100%;
		box-sizing: border-box;
		color: var(--cms-global-spare7-color);
		z-index: 999;
	}

	.QP3BhRt03qv1Gwd20Gjx {
		background-color: var(--cms-top-background-color);
		/* border-bottom: .01rem solid var(--theme-color-line); */
		line-height: normal;
		padding: 0 .2rem 0 .1rem;
	}

	.menComes {
		width: .5rem !important;
		height: .5rem !important;
	}

	.krx_BqY0Ot5YxLi63BNa {
		height: .8rem;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.v7Gu3VSoEuypS8B9EgaJ {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		width: 100%
	}

	.aG5W8rsptmZ9RO66S3cI {
		height: .7rem;
		width: 100%;
		display: flex;
		padding-right: .2rem;
		box-sizing: border-box;
		align-items: center;
		justify-content: space-between;
	}

	.aG5W8rsptmZ9RO66S3cI,
	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
	}

	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v {
		-ms-flex-pack: center;
		cursor: pointer;
		justify-content: center
	}

	.aG5W8rsptmZ9RO66S3cI .MzKJCo_kccEXopglGrWF {
		background-color: var(--theme-table-bg-color);
		cursor: pointer
	}

	.aG5W8rsptmZ9RO66S3cI .MzKJCo_kccEXopglGrWF img {
		will-change: auto !important
	}

	.aG5W8rsptmZ9RO66S3cI .DvuNDsU5DEFvvcIAXWAe {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		margin-left: auto;
	}

	.aG5W8rsptmZ9RO66S3cI .DvuNDsU5DEFvvcIAXWAe .ant-btn {
		border-radius: .1rem;
		font-family: MicrosoftYaHeiLobby;
		text-align: center
	}

	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v {
		padding: 0 .2rem;
		position: relative
	}

	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v:after {
		border-right: .01rem solid var(--theme-color-line);
		content: "";
		height: .4rem;
		position: absolute;
		right: 0;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}

	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v svg {
		font-size: .16rem
	}

	.aG5W8rsptmZ9RO66S3cI .DvuNDsU5DEFvvcIAXWAe .ant-btn {
		font-size: .16rem;
		height: .4rem;
		padding: 0 .18rem
	}

	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v {
		margin-right: -.2rem;
		padding: 0 .3rem
	}

	.aG5W8rsptmZ9RO66S3cI .pxU3k4yaAHPfT3wK4R8v svg {
		font-size: .2rem
	}

	.aG5W8rsptmZ9RO66S3cI .DvuNDsU5DEFvvcIAXWAe .ant-btn {
		font-size: .18rem;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		line-height: .2rem;
		max-width: 1.6rem;
		min-height: .45rem;
		min-width: 1.2rem;
		padding-left: .1rem;
		padding-right: .1rem;
		white-space: normal;
		word-break: break-all
	}
</style>